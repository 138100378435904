import { graphql, navigate } from "gatsby";
import { ReactElement, useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { currentResultUuidState } from "../../../organisms/result/state/atoms";
import { useResult } from "../../../organisms/result/use-cases/result-use-cases";

interface PageProps {
  params: {
    resultUuid: string;
  };
}

const Page = ({ params }: PageProps): ReactElement => {
  const setCurrentResultUuid = useSetRecoilState(currentResultUuidState);
  const result = useResult();

  useEffect(() => {
    params.resultUuid && setCurrentResultUuid(params.resultUuid);
  }, [params.resultUuid, setCurrentResultUuid]);

  useEffect(() => {
    if (!result) {
      return;
    }

    void navigate(`/results/${result.uuid}/binding/`);
  }, [result]);

  return <></>;
};

export default Page;

export const query = graphql`
  query CheckoutSuccessPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
